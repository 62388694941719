exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-async-in-place-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/async-in-place/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-async-in-place-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-building-website-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/building-website/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-building-website-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-devtools-with-no-devtools-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/devtools-with-no-devtools/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-devtools-with-no-devtools-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-moving-to-windows-2024-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/moving-to-windows-2024/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-moving-to-windows-2024-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-new-year-resolutions-2024-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/new-year-resolutions-2024/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-new-year-resolutions-2024-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-python-packaging-index-md": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/python-packaging/index.md" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-python-packaging-index-md" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-silly-things-i-dont-know-index-md": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/silly-things-i-dont-know/index.md" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-blog-silly-things-i-dont-know-index-md" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-notion-pages-asp-net-core-logging-with-azure-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/notion-pages/asp-net-core-logging-with-azure.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-notion-pages-asp-net-core-logging-with-azure-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-notion-pages-tips-for-startups-how-to-deal-with-the-lack-of-a-technical-lead-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/notion-pages/tips-for-startups-how-to-deal-with-the-lack-of-a-technical-lead.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-notion-pages-tips-for-startups-how-to-deal-with-the-lack-of-a-technical-lead-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-barberopedia-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/projects/barberopedia/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-barberopedia-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-barzellette-a-caso-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/projects/barzellette-a-caso/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-barzellette-a-caso-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-ciaofresco-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/projects/ciaofresco/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-ciaofresco-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-roommate-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/projects/roommate/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-roommate-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-stico-italy-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/projects/stico-italy/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-stico-italy-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-ynab-couple-sync-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/projects/ynab-couple-sync/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-content-projects-ynab-couple-sync-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

